import React from 'react';
import styled from '@emotion/styled';

import { Container, Link } from './styled-components';

const Wrapper = styled.header`
  width: 100%;
  padding: 30px 0;
`;

const MyContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Brand = styled.h3`
  margin: 0;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 900;
`;

const Navbar = styled.nav`
  display: flex;

  & > a {
    margin-left: 30px;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

export default function Header() {
  return (
    <Wrapper>
      <MyContainer>
        <Brand>
          <Link to="/">Ricardo Huamani</Link>
        </Brand>

        <Navbar>
          <Link to="/blog">Blog</Link>
          <Link to="/projects">Projects</Link>
        </Navbar>
      </MyContainer>
    </Wrapper>
  );
}
