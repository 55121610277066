import React from 'react';
import styled from '@emotion/styled';

import { Link } from './styled-components';

const Wrapper = styled.footer`
  padding: 30px 0;
  font-size: 1.25rem;
  text-align: center;
`;

export default function Footer() {
  return (
    <Wrapper>
      <Link to="/">drihu.com</Link>
    </Wrapper>
  );
}
