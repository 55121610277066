import styled from '@emotion/styled';
import { Link } from 'gatsby';

const Container = styled.div`
  width: 100%;
  max-width: 720px;
  margin: auto;
  padding: 0 15px;
`;

const MyLink = styled(Link)`
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`;

export { Container, MyLink as Link };
